export function getParameterByName(name, url?) {
    if (!url) url = window.location.href
    name = name.replace(/[[\]]/g, "\\$&")
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ""
    return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export function getQueryParams(url) {
    const paramArr = url.slice(url.indexOf("?") + 1).split("&")
    const params = {}
    paramArr.map((param) => {
        const [key, val] = param.split("=")
        params[key] = decodeURIComponent(val)
    })
    return params
}
