import React, {Component} from "react"
import styles from "./LabelPassword.module.css"
import {CHECK_PASSWORD_OPTIONS} from "../../../lib/functions/findErrors"
import {OkIcon} from "./svg/OkIcon"
import {ErrorIcon} from "./svg/ErrorIcon"
// import {toJS} from "mobx"
interface Props {
    text: string
    required?: boolean
    children: JSX.Element
    options?: any
    className?: string
    isVisible?: boolean
    minChars: number
}

export class LabelPassword extends Component<Props, {}> {
    private containsConditionOptions(containsOptions: any[], option: string) {
        if (!containsOptions) {
            return false
        }
        return containsOptions.includes(option)
    }

    public render() {
        const {children, text, options, required, isVisible, minChars, className = ""} = this.props
        // const {contains, length, value, id} = options
        // console.log("@@@@@@ id:", toJS(options))
        const {containsConditionOptions} = this
        return (
            <label className={`${styles.root} ${className}`}>
                {text && (
                    <p className={styles.text}>
                        {text}
                        {required && <span className={styles.required}> *</span>}
                    </p>
                )}
                {children}
                {options && isVisible && (
                    <React.Fragment>
                        <div className={styles.colorIndicators}>
                            <span className={`${options.id >= 0 ? styles.tooWeek : ""}`}></span>
                            <span className={`${options.id >= 1 ? styles.weak : ""}`}></span>
                            <span className={`${options.id >= 2 ? styles.medium : ""}`}></span>
                            <span className={`${options.id >= 3 ? styles.strong : ""}`}></span>
                        </div>
                        <div className={styles.errorWrapper}>
                            <ul className={styles.hintOptions}>
                                <li
                                    className={`${
                                        containsConditionOptions(
                                            options.contains,
                                            CHECK_PASSWORD_OPTIONS.contains.lowercase
                                        )
                                            ? styles.contains
                                            : styles.error
                                    }`}>
                                    <div>
                                        {containsConditionOptions(
                                            options.contains,
                                            CHECK_PASSWORD_OPTIONS.contains.lowercase
                                        ) ? (
                                            <OkIcon color={`#66b266`} />
                                        ) : (
                                            <ErrorIcon color={`#ff6666`} />
                                        )}
                                        <span>At least a lowercase</span>
                                    </div>
                                </li>
                                <li
                                    className={
                                        containsConditionOptions(
                                            options.contains,
                                            CHECK_PASSWORD_OPTIONS.contains.uppercase
                                        )
                                            ? styles.contains
                                            : styles.error
                                    }>
                                    <div>
                                        {containsConditionOptions(
                                            options.contains,
                                            CHECK_PASSWORD_OPTIONS.contains.uppercase
                                        ) ? (
                                            <OkIcon color={`#66b266`} />
                                        ) : (
                                            <ErrorIcon color={`#ff6666`} />
                                        )}
                                        <span>At least a uppercase</span>
                                    </div>
                                </li>
                                <li
                                    className={
                                        containsConditionOptions(
                                            options.contains,
                                            CHECK_PASSWORD_OPTIONS.contains.number
                                        )
                                            ? styles.contains
                                            : styles.error
                                    }>
                                    <div>
                                        {containsConditionOptions(
                                            options.contains,
                                            CHECK_PASSWORD_OPTIONS.contains.number
                                        ) ? (
                                            <OkIcon color={`#66b266`} />
                                        ) : (
                                            <ErrorIcon color={`#ff6666`} />
                                        )}
                                        <span>At least a number</span>
                                    </div>
                                </li>
                                <li
                                    className={
                                        containsConditionOptions(
                                            options.contains,
                                            CHECK_PASSWORD_OPTIONS.contains.symbol
                                        )
                                            ? styles.contains
                                            : styles.error
                                    }>
                                    <div>
                                        {containsConditionOptions(
                                            options.contains,
                                            CHECK_PASSWORD_OPTIONS.contains.symbol
                                        ) ? (
                                            <OkIcon color={`#66b266`} />
                                        ) : (
                                            <ErrorIcon color={`#ff6666`} />
                                        )}
                                        <span>At least a symbol</span>
                                    </div>
                                </li>
                                <li
                                    className={
                                        options.length >= minChars ? styles.contains : styles.error
                                    }>
                                    <div>
                                        {options.length >= minChars ? (
                                            <OkIcon color={`#66b266`} />
                                        ) : (
                                            <ErrorIcon color={`#ff6666`} />
                                        )}
                                        <span>At least {minChars} characters</span>
                                    </div>
                                </li>
                            </ul>
                            <span className={styles.message}>
                                Your password is
                                {options.value && (
                                    <div
                                        className={
                                            options.id === 0
                                                ? styles.tooWeek1
                                                : options.id === 1
                                                ? styles.weak1
                                                : options.id === 2
                                                ? styles.medium1
                                                : options.id === 3
                                                ? styles.strong1
                                                : ""
                                        }>
                                        <strong className={styles.value}>{options.value}</strong>
                                    </div>
                                )}
                            </span>
                        </div>
                    </React.Fragment>
                )}
            </label>
        )
    }
}
