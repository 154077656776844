import React, {Component, KeyboardEvent} from "react"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {Button} from "pages/sign-in/parts/Button"
import {LoginWrapper} from "components/LoginWrapper"
import {Model} from "Model"
import {observable} from "mobx"
import {observer} from "mobx-react"
import {LabelPassword} from "components/inputs/LabelPassword"
import {
    CHECK_PASSWORD_OPTIONS,
    findErrors,
    findPasswordErrors
} from "../../lib/functions/findErrors"
import styles from "./AccountRecovery.module.css"

const mandatory = {
    presence: {allowEmpty: false}
}

const rules = {
    email: {
        email: true
    }
}

const oneTimePassword = {
    otp: mandatory
}

const customPasswordConfirmRule = {
    password: {
        ...mandatory,
        length: {minimum: 3},
        format: {
            pattern: /^.{8,}$/,
            flags: "gm",
            message: "^password needs to have at least 8 chars at minimum."
        }
    },
    confirm_password: {
        ...mandatory,
        equality: "password"
    }
}

interface Props {
    model: Model
}

@observer
export class AccountRecovery extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    @observable
    private backendErrors: string | null = ""

    @observable
    private passwordOptions: any = {
        options: CHECK_PASSWORD_OPTIONS.default
    }

    @observable
    isVisibleOtp: boolean = false

    @observable
    isVisibleMatchPassword: boolean = false

    @observable
    private user = {
        email: "",
        otp: "",
        password: "",
        confirm_password: ""
    }

    private isValidActivateAccount = () => {
        const newRules = {
            ...rules,
            ...oneTimePassword,
            ...customPasswordConfirmRule
        }
        const {isValid, errors} = findErrors(this.user, newRules)
        this.errors = errors
        const {isValid: isValidPassword} = this.passwordOptions
        this.isVisibleMatchPassword = !isValidPassword
        return isValid && isValidPassword
    }

    private isValidEmail = () => {
        const {isValid, errors} = findErrors(this.user, rules)
        this.errors = errors
        return isValid
    }

    private isValidPassword = () => {
        const {isValid, options} = findPasswordErrors(this.user)
        this.passwordOptions = {
            isValid,
            options
        }
    }

    private onKeyUpPassword = (event: KeyboardEvent<Element>) => {
        this.isValidPassword()
    }

    private isValidConfirmPassword = () => {
        const {isValid, errors} = findErrors(this.user, customPasswordConfirmRule)
        this.errors = errors
    }

    private onKeyUpConfirmPassword = (event: KeyboardEvent<Element>) => {
        this.isValidConfirmPassword()
    }

    public render() {
        const user = this.user
        const {backendErrors, onKeyUpPassword, onKeyUpConfirmPassword} = this

        return (
            <LoginWrapper>
                <div className={styles.root}>
                    <h2 className={styles.h2}>Trouble Logging In?</h2>

                    <article className={styles.loginArea}>
                        <div className={styles.inner}>
                            <p className={styles.text}>
                                Enter your email address, and we`ll email instructions for setting a
                                new password.
                            </p>
                            <Label
                                text="Email address"
                                className={styles.inputUser}
                                error={this.errors["email"]}
                                required>
                                <Input
                                    onChange={(v) => {
                                        this.user.email = v
                                    }}
                                    value={this.user.email}
                                />
                            </Label>
                            {this.isVisibleOtp && (
                                <React.Fragment>
                                    <Label
                                        text="One time password"
                                        additionaText="(6-Digit Code from email)"
                                        className={styles.inputUser}
                                        error={this.errors["otp"]}
                                        required>
                                        <Input
                                            onChange={(v) => {
                                                this.user.otp = v
                                            }}
                                            value={this.user.otp}
                                            type="password"
                                        />
                                    </Label>
                                    <LabelPassword
                                        text="Password"
                                        required
                                        className={styles.inputPass}
                                        isVisible={this.isVisibleMatchPassword}
                                        minChars={8}
                                        options={this.passwordOptions.options}>
                                        <Input
                                            onChange={(v) => {
                                                user.password = v
                                            }}
                                            value={user.password}
                                            type="password"
                                            onKeyUp={onKeyUpPassword}
                                            onFocus={() => (this.isVisibleMatchPassword = true)}
                                            onBlur={() =>
                                                (this.isVisibleMatchPassword =
                                                    this.passwordOptions.options.value !==
                                                        CHECK_PASSWORD_OPTIONS.values.strong &&
                                                    this.passwordOptions.options.value !==
                                                        CHECK_PASSWORD_OPTIONS.values.medium)
                                            }
                                        />
                                    </LabelPassword>

                                    <Label
                                        text="Confirm Password"
                                        required
                                        className={styles.inputPass}
                                        error={this.errors["confirm_password"]}>
                                        <Input
                                            onChange={(v) => {
                                                user.confirm_password = v
                                            }}
                                            value={user.confirm_password}
                                            type="password"
                                            onKeyUp={onKeyUpConfirmPassword}
                                        />
                                    </Label>
                                </React.Fragment>
                            )}
                            <Button
                                text="Activate my account"
                                onClick={() => {
                                    if (!this.isVisibleOtp) {
                                        this.sendOneTimePasswordEmailToUser()
                                    } else {
                                        this.activateAccountUser()
                                    }
                                }}
                                className={styles.button}
                            />

                            <Button
                                text="Back To Login"
                                onClick={() => this.props.model.getHistory().push("/sign-in")}
                                className={styles.button}
                            />
                        </div>
                    </article>
                </div>
            </LoginWrapper>
        )
    }

    private async sendOneTimePasswordEmailToUser() {
        if (this.isValidEmail()) {
            const {email} = this.user
            const response = await this.props.model.sendOtpEmail(email)
            alert(`${response.message}`)
            if (response.success) {
                this.isVisibleOtp = true
            }
        }
    }

    private async activateAccountUser() {
        if (this.isValidActivateAccount()) {
            const {email, otp, password} = this.user
            const newUserCredentials = {
                email,
                otp,
                password
            }
            const response = await this.props.model.activateEmailAccount(newUserCredentials)
            alert(`${response.message}`)
            if (response.success) {
                this.props.model.getHistory().push("/sign-in")
            }
        }
    }
}
